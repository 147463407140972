import React from "react"
import Layout from "../components/Layout"
import Head from "../components/Head"
import Stripe from "../components/Stripe"
import DiagonalSlashSVG from "../components/svgs/DiagonalSlashSVG"
import "../styles/global.css"

export default function about() {
  return (
    <div className="pageContent">
      <Head />
      <Layout>
        <div className="startAfterNavbar">
          <h1 className="my-4 mx-3">About Me</h1>
        </div>
        <div className="aboutMeContent mx-4">
          <div className="container aboutMeCard shadow-sm p-3 mb-4">
            <div className="row">
              <div className="d-flex col-12 col-md-4 align-items-center justify-content-center">
                <img className="headshot headshotToAnimate" src="/profile_pic.jpg"></img>
              </div>
              <div className="col-none col-md-8">
                <hr className="d-md-none" />
                <p>
                  Hello! My name is Vincent Di Nardo, but I typically go by Vince.
                  I'm a software engineer at Microsoft based out of the Seattle area.
                  I'm also a musician and audio designer. You can find some of my previous audio work on <a href="https://soundcloud.com/vince-di-nardo/sets/redshift/s-fpn9r?si=c5fa3e29ea1e4fda9b3e76c3c8f790ce&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing">SoundCloud</a>.
                  I play mostly woodwinds (clarinet, sax, flute, ocarina), and a little bit of piano.
                </p>

                <p className="pt-4">
                  I enjoy the opportunity to combine both my creative and technical sides. Frontend design, implementing full-stack features, 
                  audio programming, audio engineering, UX in AR/VR, and the ability to work with designers are just a few examples of that. 
                </p>

                <p className="pt-4">
                  Outside of work, you can find me bouldering at a local gym, studying Japanese, enjoying a book and a cup of coffee (iced, of course)
                  at one of the many cafes here in Seattle, listening to entrepreneurship podcasts, or engaged in a heated board game with friends. 
                  Oh, and working on this website or new side projects!
                </p>

                <p className="pt-4">
                  Curious about random other skills I've developed over the years? Here's a non-exhaustive list: calligraphy, 
                  crocheting, an intermediate-beginner level of Japanese (JLPT N4), and most importantly spotting corgis from afar.
                </p>

                {/* <Stripe title="More Coming Soon!" className="mt-4 mb-3 rounded"><p>Currently being worked on 😮</p></Stripe> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
